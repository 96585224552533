.link {
	display: inline-block;
	color: $color-blue;

	&--arrow {
		svg {
			position: relative;
			top: vc(3);
			display: inline-block;
			margin-left: vc(8);
			width: vc(16);
			height: vc(16);
		}
	}

	&--phone,
	&--mail {
		font-family: $font-roboto-condensed;
		font-style: normal;
		font-weight: 400;
		font-size: vc(28);
		line-height: vc(37);
	}

	&--phone {
		color: #2d2f31;
	}

	@include desktop {
		&:hover {
			color: $color-section-title;
		}
	}
}
