.is-hidden {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.is-hide {
	// stylelint-disable-next-line declaration-no-important
	opacity: 0;
}

.for-desktop {
	@include devices {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-mobile {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}

	@include tablet {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-devices {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}