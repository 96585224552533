.btn {
	position: relative;
	display: block;
	overflow: hidden;

	span {
		position: relative;
		z-index: 2;
	}

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;

		background-color: #2d2f31;
		transform: translateZ(0);
		transition: 0.3s linear;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: 0;
		top: 0;
		left: 1px;
		width: calc(100% - 2px);
		height: 100%;
		background-color: currentColor;
	}

	@include desktop {
		&:hover {
			&::before {
				left: auto;
				right: 0;
				width: 100%;
			}
		}
	}
}
