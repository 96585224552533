.list {
	ul {
		li {
			position: relative;
			padding-left: vc(22);

			&::before {
				content: "";
				position: absolute;
				left: vc(8);
				top: vc(14);
				display: block;
				width: vc(2);
				height: vc(2);
				background-color: currentColor;
			}
		}
	}

	ol {
		counter-reset: ol;

		li {
			position: relative;
			padding-left: vc(22);

			&::before {
				counter-increment: ol;
				content: counter(ol) ". ";
				position: absolute;
				left: 0;
				display: block;
				width: vc(20);
				text-align: center;
			}
		}
	}

}
