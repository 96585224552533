.lazyBlure {
	width: 100%;
	height: 100%;
}

.lazy-blur {
	height: inherit;
	position: relative;
	overflow: hidden;
}

.lazy-blur img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.lazy-blur__imgS {
	position: static;
}

.lazy-blur__imgL {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	transition: opacity 0.3s linear;
}

.done > .lazy-blur__imgL {
	opacity: 1;
}
