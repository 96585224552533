:root {
	--width-base: #{$laptop};
	--width-current: 100vw;
	--width-multiplier: var(--width-current) / var(--width-base);

	@include desktop-xl {
		--width-current: #{$desktop-xl}px;
	}

	@include devices {
		--width-base: #{$mobile};
	}
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

html {
    position: relative;
    max-width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    font-family: $font-primary;
    font-weight: 400;
    font-size: vc(15);
    line-height: vc(27);
    &.has-cursor,
    &.has-cursor * {
        // stylelint-disable-next-line
        cursor: none !important;
    }
    &.is-lock-scroll,
    &.is-lock-scroll body,
    &.is-lock-scroll .home {
        overflow: hidden;
    }
}

body {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    text-decoration-skip: objects;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: $font-monserrat;
    color: $color-black-main;

    > .page {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
}

ol,
ul {
    margin: 0;
    padding: 0;
}

main {
    flex: 1;
}

h1,
h2,
h3,
h2,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
}

a,
button {
    outline: none;
    transition: $duration-normal;
    cursor: pointer;
    border: none;
    background-color: transparent;
}

a {
    text-decoration: none;
    color: currentColor;

    // &.a--underline {
    //     position: relative;

    //     &::before {
    //         content: "";
    //         position: absolute;
    //         left: 0;
    //         bottom: -1px;
    //         width: 0;
    //         height: 1px;
    //         background-color: currentColor;
    //         transition: $duration-normal;
    //     }

    //     @include desktop {
    //         &:hover {
    //             &::before {
    //                 width: 100%;
    //             }
    //         }
    //     }
    // }
}

svg {
    display: block;
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    fill: none;
}

figure,
picture {
    display: block;
    margin: 0;
    line-height: 0;

    img {
        width: 100%;
    }
}

img {
    vertical-align: top;
    max-width: 100%;
    user-select: none;
}

img[draggable="false"] {
    pointer-events: none;
    // stylelint-disable-next-line
    -webkit-user-drag: none;
}

fieldset {
    margin: 0;
    border: 0;
    padding: 0;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

video {
    outline: none;
    width: 100%;
    height: 100%;
}

iframe {
    display: block;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
    transition: $duration-normal;
    user-select: none;

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        color: currentColor;
        transform: translateZ(0);
    }
}

.js-lazy-load:not(.is-loaded) {
    max-height: 50vh;
}

.container {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: vc(1170);

    @include mobile {
        padding: 0 vc(40);
        width: 100%;
    }
}

.row {
    display: flex;

    @include mobile {
        display: block;
    }
}

.dropdown-wrapper {
    transition: $duration-normal;
}

/* stylelint-disable */

/*
* Немедленно переместите любую анимацию в конечную точку, если пользователь установил свое устройство в положение "prefers reduced motion".
* Это может привести к плохим(непреднамеренным) последствиям. Удалите по мере необходимости и напишите свой собственный код для prefers-reduced-motion.
*/

@media (prefers-reduced-motion: reduce) {
    *,
    *:before,
    *:after {
        animation-duration: 0.001s !important;
        animation-delay: 0s !important;
        transition-duration: 0.001s !important;
        transition-delay: 0s !important;
    }
}

/* stylelint-enable */
