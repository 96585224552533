.footer {
	overflow: hidden;
	margin-top: auto;
	padding: vc(70) 0 vc(70);
	background-color: #121213;

	.container {
		@include mobile {
			padding: 0;

			> .row {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
			}
		}
	}

	&__divider {
		position: relative;
		display: flex;
		align-items: center;
		height: vc(30);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 1px;
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.4) 52.08%,
				rgba(255, 255, 255, 0) 100%
			);
			transition: 0s;
		}

		@include mobile {
			&::before {
				left: 50%;
				top: vc(-17);
				height: 1px;
				width: vc(160);
				background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0.4) 52.08%,
					rgba(255, 255, 255, 0) 100%
				);
				transform: translateX(-50%);
			}
		}
	}

	&__logo {
		width: vc(183);
		height: vc(30);
		color: $color-blue;

		@include desktop {
			&:hover {
				opacity: 0.6;
			}
		}

		@include mobile {
			margin-bottom: vc(24);
		}
	}

	&__description {
		font-weight: 700;
		font-size: vc(10);
		line-height: vc(15);
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: #8d9aa7;

		@include desktop {
			margin-left: vc(24);
			margin-right: auto;
			padding-left: vc(25);
			width: vc(190);
		}

		@include mobile {
			width: 50%;

			&::before {
				top: vc(-10);
			}
		}
	}

	&__phone {
		margin-left: auto;
		font-weight: 800;
		font-size: vc(18);
		line-height: vc(33);
		color: #8d9aa7;

		@include desktop {
			&:hover {
				opacity: 0.6;
			}
		}

		@include mobile {
			display: block;
			margin-bottom: vc(30);
		}
	}

	&__mail {
		font-size: vc(18);
		line-height: vc(33);
		color: $color-blue;

		@include desktop {
			margin-left: vc(36);
			margin-right: vc(92);
			padding-left: vc(37);

			&:hover {
				opacity: 0.6;
			}
		}

		@include mobile {
			margin-bottom: vc(42);
			display: block;

			&::after {
				content: "";
				position: absolute;
				left: 50%;
				bottom: vc(-17);
				height: 1px;
				width: vc(160);
				background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0.4) 52.08%,
					rgba(255, 255, 255, 0) 100%
				);
				transform: translateX(-50%);
			}
		}
	}

	&__address {
		font-size: vc(12);
		line-height: vc(19);
		color: $color-white;

		@include desktop {
			margin-top: vc(4);
			margin-right: vc(147);
		}

		@include mobile {
			position: relative;
			margin: 0 auto;
			width: 50%;
		}
	}

	&__right {
		margin-top: vc(4);

		.row {
			align-items: center;
		}

		@include mobile {
			order: -1;
			margin-top: 0;
			margin-bottom: vc(58);
			padding: vc(60) 0;
			width: 100%;
			background-color: #121213;
		}
	}

	@include mobile {
		padding-top: 0;
		background-color: #000;
	}
}
