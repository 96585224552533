.header {
	overflow-x: hidden;
	width: 100%;
	margin-top: vc(60);
	margin-bottom: vc(60);
	transition: 0.6s;

	body:has(.home) & {
		position: absolute;
	}

	a {
		@include desktop {
			&:hover {
				opacity: 0.6;
			}
		}
	}

	.container {
		padding: 0;
	}

	.row {
		align-items: center;
	}

	.link__email {
		@include mobile {
			display: block;
			font-family: $font-roboto-condensed;
			font-size: vc(28);
			line-height: vc(17);
		}
	}

	.link__phone {
		transition: $duration-normal;

		@include mobile {
			display: block;
			font-family: $font-roboto-condensed;
			font-weight: 400;
			font-size: vc(28);
			line-height: vc(37);
		}
	}

	.btn {
		display: flex;
		border: 1px solid $color-blue;
		color: $color-section-title;

		&::before {
			z-index: -1;
			background-color: $color-blue;
		}

		&::after {
			background-color: transparent;
		}

		svg {
			margin-left: vc(5);
			width: vc(16);
			height: vc(16);
		}

		@include mobile {
			margin-top: vc(34);
			padding: vc(9) vc(34);
			width: vc(200);
		}
	}

	&__divider {
		position: relative;
		display: flex;
		align-items: center;
		padding-left: vc(25);
		margin-left: vc(25);
		height: vc(50);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 1px;
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0.4) 0%,
				rgba(138, 141, 155, 0.4) 54.69%,
				rgba(255, 255, 255, 0.4) 100%
			);
			transition: 0;
		}

		@include mobile {
			height: auto;
		}
	}

	&__logo {
		display: block;
		order: -1;
		width: vc(183);
		height: vc(28);
		color: $color-blue;

		body:has(.home) & {
			color: currentColor;
		}

		@include mobile {
			margin-top: vc(20);
			margin-left: vc(38);
			width: vc(183);
			height: vc(28);
		}
	}

	&__description {
		margin-right: auto;
		width: vc(220);
		font-style: normal;
		font-weight: 700;
		font-size: vc(10);
		line-height: 150%;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: $color-section-title;
		transition: $duration-normal;

		@include mobile {
			display: block;
			margin: 0;
			padding: vc(7) vc(20);
			width: 100%;
			height: auto;
			line-height: 100%;
			background-color: #2d2f31;
			color: $color-white;
			text-align: center;

			&::before {
				display: none;
			}
		}
	}

	&__menu {
		display: flex;

		a {
			@include mobile {
				opacity: 0;

				.menu-open & {
					opacity: 1;
				}
			}
		}

		.header__logo {
			margin: 0;
			color: #25262b;
		}

		.link {
			color: $color-blue;

			body:has(.home) & {
				color: currentColor;
			}

			&--arrow {
				svg {
					@include desktop {
						display: none;
					}
				}
			}

			@include desktop {
				&:hover {
					color: currentColor;
				}
			}
		}

		&-wrapper {
			@include desktop {
				display: flex;
			}

			@include mobile {
				overflow-y: scroll;
				height: 100%;
				padding-bottom: vc(80);
				background: $color-white;
			}
		}

		&-nav {
			display: flex;
			align-items: center;

			ul {
				@include desktop {
					display: flex;
				}

				@include mobile {
					li {
						margin-bottom: vc(14);
						opacity: 0;
						transition: 0.3s ease-out;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			&--1 {
				@include mobile {
					a {
						border-bottom: 1px dashed currentColor;
					}
				}
			}

			&--2 {
				li {
					margin-right: vc(60);
					color: currentColor;
					transform: translateZ(0);

					&:last-child {
						margin-right: vc(0);
					}
				}

				@include mobile {
					.link {
						color: currentColor;
					}
				}
			}

			&--3 {
				.header__divider {
					margin-left: vc(31);
					padding-left: vc(30);

					@include mobile {
						margin-left: 0;
						padding-left: 0;
					}
				}
			}

			.menu-open & {
				$delay: 6;
				$speed: 0.75;

				ul {
					li {
						opacity: 1;
					}
				}

				&:nth-child(1) {
					ul {
						@for $i from 1 through 0 {
							li:nth-child(#{$i}) {
								transition-delay: #{$delay / 10}s;
								$delay: ($i * $speed) + 1;
							}
						}
					}
				}

				&:nth-child(2) {
					ul {
						@for $i from 6 through 0 {
							li:nth-child(#{$i}) {
								transition-delay: #{$delay / 10}s;
								$delay: ($i * $speed) + 2;
							}
						}
					}
				}

				&:nth-child(3) {
					ul {
						@for $i from 3 through 0 {
							li:nth-child(#{$i}) {
								transition-delay: #{$delay / 10}s;
								$delay: ($i * $speed) + 6;
							}
						}
					}
				}

				&:nth-child(4) {
					ul {
						@for $i from 4 through 0 {
							li:nth-child(#{$i}) {
								transition-delay: #{$delay / 10}s;
								$delay: ($i * $speed) + 7;
							}
						}
					}
				}
			}

			@include mobile {
				display: block;
				padding: vc(44) vc(40);
				border-bottom: 1px solid #dde2e8;

				&--2,
				&--3 {
					padding: vc(24) vc(40);
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}

		@include desktop {
			padding-right: vc(5);
		}

		@include mobile {
			position: fixed;
			top: 0;
			z-index: 99;
			display: block;
			margin-left: vc(40);
			width: calc(100% - #{vc(40)});
			height: 100vh;
			transform: translateX(100%);
			transition: 0.6s ease-out;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: -150vw;
				z-index: -2;
				display: block;
				width: 300vw;
				height: 100vh;
				background: #121213;
				transition: $duration-normal;
				opacity: 0;
				visibility: hidden;
			}

			.menu-open & {
				transform: translateX(0);

				&::after {
					opacity: 0.8;
					visibility: visible;
				}
			}
		}
	}

	&__burger {
		position: absolute;
		top: vc(44);
		right: vc(40);
		z-index: 100;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		padding: 0 vc(7);
		border: 1px solid currentColor;
		width: vc(28);
		height: vc(28);
		color: $color-blue;
		transform-origin: center;

		body:has(.home) & {
			color: currentColor;
		}

		span {
			display: block;
			border-radius: vc(2);
			height: vc(2);
			background-color: #2d2f31;
			transition: $duration-normal;

			&:nth-child(1) {
				margin-bottom: vc(4);
				width: vc(12);
			}

			&:nth-child(2) {
				width: vc(8);
			}

			.menu-open & {
				background-color: #2d2f31 !important;
			}
		}

		&.active {
			span {
				width: vc(12);

				&:nth-child(1) {
					transform: rotate(45deg) translate(vc(2), vc(2));
				}

				&:nth-child(2) {
					transform: rotate(-45deg) translate(vc(2), vc(-2));
				}
			}
		}
	}

	@include mobile {
		position: relative;
		top: 0;
		z-index: 100;
		overflow: visible;
		margin: 0;
		padding-bottom: vc(20);
		transform: translateY(0);

		&.header--fixed {
			position: fixed !important;
			top: vc(-116);
			transform: translateY(100%);
			transition: transform 0.6s;
		}

		&.header--hide {
			position: fixed !important;
			top: vc(-118);
			transform: translateY(-100%);
			transition: transform 0.6s;
		}
	}
}
