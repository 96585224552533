.card {
	&--technology {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: vc(38);
		width: vc(370);
		height: vc(240);
		box-shadow: $shadow-cards-main;
		background-color: $color-white;

		img {
			display: block;
			object-fit: contain;
			max-height: 100%;
		}

		@include mobile {
			padding: vc(20);
			width: vc(150);
			height: vc(110);
		}
	}

	&--brif {
		padding: vc(30) vc(40) vc(46);
		width: vc(370);
		height: vc(340);
		box-shadow: 0 vc(2) vc(3) rgba(37, 38, 43, 0.15),
			0 vc(5) vc(15) rgba(37, 38, 43, 0.15),
			0 vc(20) vc(35) rgba(37, 38, 43, 0.07),
			0 vc(45) vc(65) rgba(37, 38, 43, 0.07);
		background-color: $color-white;

		> svg {
			position: relative;
			left: vc(-7);
			display: block;
			margin-bottom: vc(10);
			width: vc(54);
			height: vc(54);
		}

		h3 {
			margin-bottom: vc(10);
			font-family: $font-roboto-condensed;
			font-style: normal;
			font-weight: 400;
			font-size: vc(28);
			line-height: vc(37);
			color: #2d2f31;
		}

		> span {
			display: block;
			margin-bottom: vc(24);
			color: #8d9aa7;
		}

		a.btn {
			display: inline-block;
			padding: vc(10) vc(36);

			&::before {
				background-color: $color-blue;
			}

			&::after {
				background-color: $color-section-title;
			}

			span {
				display: inline;
				color: $color-white;
			}

			svg {
				position: relative;
				top: vc(2);
				z-index: 2;
				display: inline;
				width: vc(16);
				height: vc(16);
				color: $color-white;
			}

			@include mobile {
				margin-bottom: vc(-8);
			}
		}

		@include mobile {
			padding: vc(20) vc(20) vc(40);
			margin-bottom: vc(20);
			width: 100%;
			height: auto;

			&:last-child {
				margin-bottom: 0;
			}

			> * {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&--news {
		display: flex;
		flex-direction: column;
		width: vc(370);
		height: vc(510);
		box-shadow: $shadow-cards-main;
		background-color: $color-white;
		transition: $duration-normal;

		.card__wrapper {
			display: flex;
			flex-direction: column;
			padding: vc(30);
			height: 100%;

			@include mobile {
				padding: vc(14) vc(20) vc(30);
			}
		}

		figure {
			width: 100%;
			height: vc(260);
			transition: $duration-normal;

			@include desktop {
				opacity: 0.4;
			}

			@include mobile {
				height: vc(180);
			}
		}

		h3 {
			overflow: hidden;
			margin-bottom: vc(14);
			font-family: $font-roboto-condensed;
			font-style: normal;
			font-weight: 400;
			font-size: vc(21);
			line-height: vc(28);
			text-overflow: ellipsis;
			color: #494b57;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			transition: $duration-normal;
		}

		span {
			display: block;
			overflow: hidden;
			margin-bottom: auto;
			font-size: vc(12);
			line-height: vc(19);
			text-overflow: ellipsis;
			color: #8d9aa7;
			display: -webkit-box;
			-webkit-line-clamp: 10;
			-webkit-box-orient: vertical;
			transition: $duration-normal;
		}

		.link {
			margin-top: vc(24);
			font-size: vc(12);
			line-height: vc(20);
		}

		&:has(figure) {
			h3 {
				-webkit-line-clamp: 2;
			}
			span {
				-webkit-line-clamp: 4;
			}
		}

		@include desktop {
			&:hover {
				box-shadow: 0 vc(2) vc(3) rgba(37, 38, 43, 0.05),
					0 vc(5) vc(15) rgba(37, 38, 43, 0.07),
					0 vc(20) vc(35) rgba(37, 38, 43, 0.09),
					0 vc(45) vc(65) rgba(37, 38, 43, 0.11);

				figure {
					opacity: 1;
				}

				h3,
				span {
					color: #121213;
				}
			}
		}

		@include mobile {
			width: vc(290);
			height: vc(441);
		}
	}
}
