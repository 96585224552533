.section {
	p {
		color: $color-section-p;
	}

	&__title {
		margin-bottom: vc(24);
		font-family: $font-roboto-condensed;
		font-style: normal;
		font-size: vc(44);
		line-height: vc(55);
		color: $color-section-title;

		span {
			color: $color-blue;
		}
	}
}
